import { render, staticRenderFns } from "./Description.vue?vue&type=template&id=0132e02d&scoped=true&"
import script from "./Description.vue?vue&type=script&lang=ts&"
export * from "./Description.vue?vue&type=script&lang=ts&"
import style0 from "./Description.vue?vue&type=style&index=0&id=0132e02d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0132e02d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText})
