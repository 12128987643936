import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home-View.vue";
import Party from "../views/Party-View.vue";
import Contact from "../views/Contact-View.vue";
import Directions from "../views/Directions-View.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home-View",
    component: Home,
  },
  {
    path: "/party",
    name: "Party-View",
    component: Party,
  },
  {
    path: "/contact",
    name: "Contact-View",
    component: Contact,
  },
  {
    path: "/directions",
    name: "Directions-View",
    component: Directions,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
