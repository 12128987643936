function getDescriptionText(): string {
  return `Liebe Freundinnen und Freunde,<br /><br />wir freuen uns sehr, dass Ihr mit uns unsere Hochzeit feiern möchtet.<br />Am 31.08.2024 starten wir um 16:30 Uhr. Ihr könnt euch gerne schon ab 16:00 Uhr gemütlich einfinden.<br /><br />Es ist uns ein großer Wunsch, dass ihr euch super wohl fühlt und wir mit euch ausgiebig und ausgelassen feiern können.<br />In diesem Sinne gibt es keinen Dresscode und der Tag kann sehr gerne im Kalender eurer Baby- und Hundesitter vorgemerkt werden.<br /><br />Ihr findet alle weiteren Infos rund um die Party unter den entsprechenden Menupunkten.<br />Um besser planen zu können, bitten wir euch diese kurze <a target="_blank" class="blacklinkUnderlined" href="https://forms.gle/9fgmVe6MrxhCZJZP9">Umfrage</a> bis zum 01. Juni für uns auszufüllen.<br />
  Bevor ihr fragt, ihr Schlingels: Wir erwarten wirklich keine Geschenke von euch. Falls ihr es absolut nicht lassen könnt, dann freuen wir uns über Geld.<br /><br />
  Küsschen<br />
  Malika & Frank`;
}

function getAfterPartyText(): string {
  return `Ihr wunderbaren Menschen,<br /><br />wir hatten Dank Euch die beste Party unseres Lebens. Die Feier war schöner als wir sie uns jemals hätten vorstellen können.<br /><br />Vielen Dank, dass ihr da wart. Dass ihr mit uns ausgelassen gefeiert habt. Tausend Dank für eure Hilfe beim Auf- und Abbau. Eure großzügigen und kreativen Geschenke haben uns überwältigt.<br /><br />Marana und Leonie - ihr seid die Besten und wirklich der absolute Wahnsinn.<br />Gisela und Horst - tausend Dank für eine große Unterstützung.<br />Der Mitternachtssnack war ganz großes Kino und unsere DJs Jalle & Henna, Kappa und Elies haben den Laden perfekt angezündet. Vielen Dank an Tom für die tollen Bilder.<br /><br />Die Fotos könnt ihr euch in Originalqualität als große ZIP-Datei <a target="_blank" class="blacklinkUnderlined" href="https://malika-und-frank.de/pictures/20240831_Hochzeits_Party.zip">hier</a> herunterladen (am besten am Computer).<br />Falls ihr die Fotos im Browser betrachten und ausgewählt herunterladen möchtet, folgt bitte diesem <a target="_blank" class="blacklinkUnderlined" href="https://share.icloud.com/photos/039KCojv8YyVvRqjcLm0R4BbA">Link</a>.<br /><br />Eure Malika & Euer Frank`;
}

function getPartyText(): string {
  return `Bisher haben wir nur einen groben Plan gefasst und es gibt noch viel Gestaltungsspielraum.<br />
  Ab 16 Uhr können sich alle Gäste einfinden und die ersten Drinks zu sich nehmen. <br />Um 16:30 Uhr möchten wir mit einem offiziellen Teil die Party eröffnen.<br />Damit niemand hungrig sein muss, gibt es im direkten Anschluss Essen vom Grill.<br />
  Nach dem Essen gibt es noch Raum für weitere und eher lockere Programmpunkte.<br />
  Im Laufe des Abends wird dann hoffentlich ordentlich gebechert und zu krassen Live DJs abgezappelt.<br /><br />

  <h4>Wie ihr euch einbringen könnt</h4>

  Falls ihr unser Partyprogramm mitgestalten möchtet (heimlich oder offiziell), könnt Ihr euch gerne an unsere Trauzeuginnen Marana und Leonie oder an uns wenden.

  <br /><br />
  <h5>Essen</h5>
  Für Grillgut, vegetarische und vegane Alternativen ist selbstverständlich gesorgt. Wir würden uns jedoch sehr über Salatspenden freuen. Bitte meldet euch hierzu ebenfalls bei unseren Trauzeuginnen.<br />
  Wir planen zusätzlich einen Mitternachtssnack bei dem uns ebenfalls unter die Arme gegriffen werden kann.

  <br /><br />
  <h5>Getränke</h5>
  Es wird genügend alkoholfreie und alkoholische Getränke geben. Trotzdem ist euer Lieblingsschnaps herzlich willkommen.

  <br /><br />
  <h5>Fotos</h5>
  Falls ihr an dem Abend Lust habt ein paar Schnappschüsse zu schießen, dann schnappt euch die Kamera, die wir zur Verfügung stellen werden und geht auf die Jagd. Außerdem könnt ihr uns auch gerne nach der Party sämtliches Material zukommen lassen, damit wir es dem Fotopool hinzufügen und hier auf der Webseite mit allen teilen können.`;
}

function getContactText(): string {
  return `Hier findet ihr kurz und knapp die Emailadressen von unseren Trauzeuginnen und uns.<br /><br />
  <ul class="no-bullets">
  <li>Marana: <a class="blacklinkUnderlined" href="mailto:marana.hartock@gmail.com">marana.hartock@gmail.com</a></li>
  <li>Leonie: <a class="blacklinkUnderlined" href="mailto:leonie.elmer@hotmail.de">leonie.elmer@hotmail.de</a></li>
  <li>Malika: <a class="blacklinkUnderlined" href="mailto:m.mabrouki@posteo.de">m.mabrouki@posteo.de</a></li>
  <li>Frank: <a class="blacklinkUnderlined" href="mailto:frank.thielen@posteo.de">frank.thielen@posteo.de</a></li>
  </ul>`;
}

function getCampingDescriptionText(): string {
  return `Für die Outdoor Übernachtende haben wir eine kleine Skizze angefertigt. In eurem Navi könnt ihr hierfür die folgende Adresse eingeben: <br /><br />In der Hohl 6, 56659 Burgbrohl<br /><br />Ganz in der Nähe gibt es eine Kreuzung mit einem Feldweg. Dieser führt euch sowohl zum Parkplatz für eure Camper-Fahrzeuge als auch zum Garten in dem ihr eure Zelte aufschlagen könnt. <br /><br />An alle die Zelten: Bitte haltet den Schotterparkplatz frei für die Camper-Fahrzeuge und parkt eure PKWs in den umliegenden Strassen.<br /><br />Der Garten gehört zu Franks Elternhaus und dort dürft ihr auch sehr gerne das Badezimmer nutzen.<br /><br />Von hier aus könnt ihr prima zu Fuß zum Schützenhaus laufen.`;
}

function getDirectionsText(): string {
  return `Die Schützenhalle in der wir feiern, hat die Adresse: Neuwiese, 56659 Burgbrohl.<br /><br />

  Unter diesem <a class="blacklinkUnderlined" target="_blank" href="https://maps.app.goo.gl/mon3kc1yfeEpfW8T6">Link</a> findet ihr den genauen Standort.<br /><br/>
  <h4>Parken</h4>
  Bitte parkt innerhalb des Ortes und geht die letzten Meter zu Fuß. Der Feldweg muss für landwirtschaftlichen Verkehr freigehalten werden.

  <br /><br />
  <h4>Taxiunternehmen</h4>
  <ul class="no-bullets">
  <li><a class="blacklinkUnderlined" target="_blank" href="https://maps.app.goo.gl/RY3XNdzGhpCKq6iC8">City Taxi</a></li>
  <li><a class="blacklinkUnderlined" target="_blank" href="https://maps.app.goo.gl/AaeTNczz3LTjET6DA">Rhein Taxi 24 GmbH</a></li>
  <li><a class="blacklinkUnderlined" target="_blank" href="https://maps.app.goo.gl/dmKFYB8DXFezJAt58">Breisiger Mietwagen</a></li>
  <li>...</li>
  </ul>

  <br />
  <h4>Übernachtungsmöglichkeiten</h4>

  Ihr habt verschiedene Übernachtungsmöglichkeiten. Es wird eine Wiese in direkter Nähe zur Location geben auf der ihr legal eure Zelte aufschlagen dürft. <br />Außerdem gibt es Ferienwohnungen im Ort und im Nebenort, die fußläufig zu erreichen sind. <br />Im 8km entfernten Bad Breisig gibt es ein paar Hotels & Pensionen. Diese sind allerdings nur mit einem Taxi/Auto zu erreichen.<br /><br />

  <h5>Camping</h5>
  Adresse folgt

  <br /><br />

  <h5>Ferienwohnungen</h5>

  <ul class="no-bullets">
  <li><a class="blacklinkUnderlined" target="_blank" href="https://www.booking.com/hotel/de/ferienwohnung-himmel-un-aad-im-brohltal-rhein-ahr-eifel-burgbrohl-lutzingen.de.html?aid=2068758;label=seogooglelocal-link-imagesaow-hotel-7676782_grp-2_gendate-20230324;utm_source=seogooglelocal;utm_medium=description;utm_term=hotel-7676782;utm_campaign=de">Himmel un Ääd</a></li>
  <li><a class="blacklinkUnderlined" target="_blank" href="https://www.fewo-direkt.de/ferienwohnung-ferienhaus/p2476817">Atelierloft mit "toskanischem" Blick in die Vordereifel</a></li>
  <li><a class="blacklinkUnderlined" target="_blank" href="https://www.bestfewo.de/expose/22506261#map">Ferienwohnung für 4 Personen</a></li>
  <li><a class="blacklinkUnderlined" target="_blank" href="https://www.e-domizil.de/rental/27652550a753d58e49b59e4baa2e45c2?adword=edom_gvf%2FDE%2Fs%2Fct%3Ds%2Fn-a%2Fn-a%2F5460aecab790d&utm_source=gvf">64 m² Ferienwohnung</a></li>
  <li><a class="blacklinkUnderlined" target="_blank" href="https://www.bell-e-vue.de/#top-b">Ferienhaus BELL e VUE</a></li>
  <li>...</li>
  </ul>

  <br />

  <h5>Hotels & Pensionen</h5>
  <ul class="no-bullets">
  <li><a class="blacklinkUnderlined" target="_blank" href="https://maps.app.goo.gl/irbhHSq4M2HL6k3y9">Hotel Quellenhof Bad Breisig</a></li>
  <li><a class="blacklinkUnderlined" target="_blank" href="https://maps.app.goo.gl/GAYEHK9WTobDLUYd9">Hotel Rhein-Residenz</a></li>
  <li>...</li>
  </ul>
  `;
}

export {
  getDescriptionText,
  getPartyText,
  getContactText,
  getDirectionsText,
  getCampingDescriptionText,
  getAfterPartyText,
};
