import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { messages, defaultLocale } from "@/i18n";
import VueI18n from "vue-i18n";
import vuetify from "./plugins/vuetify";

Vue.use(VueI18n);
Vue.config.productionTip = false;

const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
});

new Vue({
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

export { i18n };
