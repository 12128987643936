import { Locales } from "../i18n/locales";

import de from "../i18n/de.json";
import en from "../i18n/en.json";

export const messages = {
  [Locales.DE]: de,
  [Locales.EN]: en,
};

export const defaultLocale = navigator.language.includes("de-")
  ? Locales.DE
  : Locales.EN;
